import { ref, onMounted } from '@vue/composition-api'
import GetProfileList from '@/firestore/user/GetProfileList'
import UpdateSingleData from '@/firestore/data/UpdateSingleData'
import GetUserList from '@/firestore/user/GetUserList'

export default function useListUser() {
  const profileList = ref([])
  const profileDoneList = ref([])
  const userList = ref([])
  const status = ref(1)
  const updateStatus = ref(false)

  const filterProfile = profile => {
    const { uid } = profile
    return userList.value.find(v => v.uid === uid).status.status
  }
  const fetch = async () => {
    status.value = 2

    const userLists = new GetUserList()
    const resp = await userLists.fetch()
    userList.value = resp.data.filter(v => v.type === 'consumer')

    const profileLists = new GetProfileList()
    const res = await profileLists.fetch()

    profileList.value = res.data.filter(v => filterProfile(v) !== 'done')
    profileDoneList.value = res.data.filter(v => filterProfile(v) === 'done')

    status.value = 1
  }
  /**
   * DBに更新する
   * @param {String} docID
   * @param {String} userStatus
   */
  const update = async (dic, userStatus) => {
    const data = dic
    data.status = userStatus
    const d = {
      collection: 'user',
      data,
    }
    const singleData = new UpdateSingleData()
    await singleData.update(d)
    updateStatus.value = true
  }
  onMounted(async () => {
    fetch()
  })
  const refetch = () => {
    fetch()
  }
  return {
    fetch,
    profileList,
    profileDoneList,
    userList,
    status,
    update,
    updateStatus,
    refetch,
  }
}
