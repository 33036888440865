<template>
  <div>
    <b-alert
      variant="primary"
      show
      class="mt-1"
    >
      <div class="alert-body">
        求職者の<b>アンケート</b>を表示しています。
      </div>
    </b-alert>
    <b-card>
      <b-card-text
        v-if="Object.keys(dataDic).length === 0"
      >
        まだアンケートは回答されていません。
      </b-card-text>
      <b-card-text
        v-else
      >
        <b-row
          v-if="dataDic.workingStatus"
        >
          <b-col
            cols="4"
            class="text-right"
          >
            現在の状況
          </b-col>
          <b-col>
            {{ dataDic.workingStatus.labelName }}
          </b-col>
        </b-row>
        <hr>
        <b-row
          v-if="dataDic.deadline"
        >
          <b-col
            cols="4"
            class="text-right"
          >
            転職時期
          </b-col>
          <b-col>
            {{ dataDic.deadline.labelName }}
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            cols="4"
            class="text-right"
          >
            希望勤務地
          </b-col>
          <b-col>
            <div class="">
              {{ dataDic.place }}
            </div>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            cols="4"
            class="text-right"
          >
            希望職種
          </b-col>
          <b-col>
            <div
              v-for="(item, index) in dataDic.jobtype"
              :key="index"
            >
              {{ item }}
            </div>
            <div class="">
              {{ dataDic.jobtype_other }}
            </div>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            cols="4"
            class="text-right"
          >
            希望年収
          </b-col>
          <b-col>
            <div
              v-for="(it, index_) in dataDic.incom"
              :key="index_"
            >
              {{ it }}
            </div>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BAlert, BCard, BCardText,
} from 'bootstrap-vue'
import { showAge } from '@/components/js/filter/birthday'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    dataDic: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
      showAge,
    }
  },
}
</script>
