import firebase from 'firebase/app'

export default class GetUserList {
  constructor() {
    this.label = ''
  }

  async fetch() {
    const dataList = []
    try {
      await firebase.firestore().collection('profile')
        .where('displayStatus', '==', 'active')
        .orderBy('insertTime', 'desc')
        .get()
        .then(sn => {
          if (!sn.empty) {
            sn.forEach(doc => {
              dataList.push(doc.data())
            })
          }
        })

      const response = {
        status: 'success',
        data: dataList,
      }
      return response
    } catch (error) {
      window.console.log('memo', error)
      const response = {
        status: 'error',
        msg: error,
      }
      return response
    }
  }
}
