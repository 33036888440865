<template>
  <div>
    <b-alert
      variant="primary"
      show
      class="mt-1"
    >
      <div class="alert-body">
        求職者の<b>プロフィール</b>を表示しています。
      </div>
    </b-alert>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col
            cols="4"
            class="text-right"
          >
            氏名
          </b-col>
          <b-col>
            {{ dataDic.lastName }} {{ dataDic.firstName }}
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            cols="4"
            class="text-right"
          >
            氏名(ふりがな)
          </b-col>
          <b-col>
            {{ dataDic.lastName_kana }} {{ dataDic.firstName_kana }}
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            cols="4"
            class="text-right"
          >
            性別
          </b-col>
          <b-col>
            {{ dataDic.gender.labelName || '記入なし' }}
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            cols="4"
            class="text-right"
          >
            生年月日
          </b-col>
          <b-col>
            {{ dataDic.birthday }}生 ({{ showAge(dataDic.birthday) }}歳)
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            cols="4"
            class="text-right"
          >
            住所
          </b-col>
          <b-col>
            <div v-if="dataDic.address">
              <div
                class=""
              >
                {{ showZip(dataDic.address.zipcode) || '' }}
              </div>
              <div class="mt-1">
                {{ dataDic.address.prefecture || '' }}{{ dataDic.address.address1 || '' }}
              </div>
              <div class="mt-1">
                {{ dataDic.address.address2 || '' }}
              </div>
            </div>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            cols="4"
            class="text-right"
          >
            メールアドレス
          </b-col>
          <b-col>
            <div>
              <div
                class=""
              >
                {{ dataDic.mail || '' }}
              </div>
            </div>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            cols="4"
            class="text-right"
          >
            電話番号
          </b-col>
          <b-col>
            <div>
              <div
                class=""
              >
                {{ dataDic.phone || '' }}
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BAlert, BCard, BCardText,
} from 'bootstrap-vue'
import { showAge } from '@/components/js/filter/birthday'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    dataDic: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
      showAge,
    }
  },
  methods: {
    showZip(val) {
      if (val !== '') {
        const front = String(val).substring(0, 3)
        const back = String(val).substring(3, String(val).length)
        return `〒${front}-${back}`
      }
      return ''
    },
  },
}
</script>
