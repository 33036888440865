<template>
  <div>
    <b-list-group>
      <b-list-group-item
        active
      >
        <div class="d-flex justify-content-between">
          <div class="">
            ユーザ一覧
          </div>
          <div class="">
            全 {{ dataList.length }} 件
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in dataList"
        :key="index"
      >
        <b-row>
          <!-- <b-col lg="1" cols="2">
          </b-col> -->
          <b-col lg="6">
            <div class="d-flex">
              <b-avatar
                size="28"
                :variant="`light-${getColor(index)}`"
              >
                {{ String(item.lastName).substring(0, 1).toUpperCase() }}
              </b-avatar>
              <div class="ml-1 text-font-size-md text-bold">
                {{ item.lastName }} {{ item.firstName }}({{ item.lastName_kana }} {{ item.firstName_kana }}) 様
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="">
              {{ item.mail }}
            </div>
          </b-col>
          <b-col class="text-right mb-1">
            <div v-if="item && userList.find(v => v.uid === item.uid)">
              <b-badge
                pill
                :variant="getStatusColor(userList.find(v => v.uid === item.uid).status.status)"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="10"
                  class=""
                />
                <span class="labeltext">
                  <small>{{ userList.find(v => v.uid === item.uid).status.labelName }}</small>
                </span>
              </b-badge>
            </div>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-between">
          <div class="date-text mt-1">
            <small>作成：{{ formatDateMinutes(item.insertTime) }}</small>
          </div>
          <div class="">
            <b-button
              variant="outline-primary"
              class="btn-wishlist"
              size="sm"
              @click="showModal(item)"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50"
              />
              <span>詳細</span>
            </b-button>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <b-modal
      v-model="isShowModal"
      size="lg"
      title="求職者情報"
      ok-only
      ok-variant="flat-secondary"
      ok-title="閉じる"
    >
      <UserInfo
        :data-dic="detailDic"
        :hearing-dic="hearingDic"
        :user-dic="userDic"
        @child-action="childAction"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BListGroup, BListGroupItem, BAvatar, BButton, BBadge,
} from 'bootstrap-vue'
import formatDateMinutes from '@/components/js/filter/formatDateMinutes'
import { ref } from '@vue/composition-api'
import UserInfo from '@/components/admin/user/UserInfo.vue'
import GetSingleData from '@/firestore/data/GetSingleData'
// import GetSigleDataByDocID from '@/firestore/data/GetSigleDataByDocID'

export default {
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BButton,
    BBadge,
    UserInfo,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    userList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const colors = ['primary', 'secondary', 'success', 'warning', 'danger', 'info']
    const getColor = index => {
      const m = index % colors.length
      return colors[m]
    }
    const isShowModal = ref(false)
    const detailDic = ref({})
    const hearingDic = ref({})
    const userDic = ref({})

    const getHearing = async uid => {
      const d = {
        collection: 'hearing',
        targetIdLabel: 'uid',
        id: uid,
      }
      const sigleDataByDocID = new GetSingleData()
      const res = await sigleDataByDocID.fetch(d)
      return res.data
    }
    const showModal = async item => {
      isShowModal.value = true
      detailDic.value = props.dataList.find(v => v.docID === item.docID)
      hearingDic.value = await getHearing(item.uid)
      // const d = {
      //   collection: 'user',
      //   docID: detailDic.value.uid,
      // }
      // const sigleDataByDocID = new GetSigleDataByDocID()
      // const res = await sigleDataByDocID.fetch(d)
      userDic.value = props.userList.find(v => v.uid === item.uid)
    }
    const statusMenu = [
      { labelName: 'プロフ作成済み', status: 'profile' },
      { labelName: 'ヒアリング準備中', status: 'hearing' },
      { labelName: '提案準備中', status: 'proposition' },
      { labelName: '面接準備中', status: 'contact' },
      { labelName: '面接決定', status: 'interview' },
      { labelName: '完了', status: 'done' },
    ]
    const statusColor = ['success', 'primary', 'warning', 'info', 'danger', 'secondary']
    const getStatusColor = status => {
      const index = statusMenu.findIndex(v => v.status === status)
      const m = index % colors.length
      return statusColor[m]
    }
    const childAction = () => {
      emit('child-action')
    }
    return {
      formatDateMinutes,
      getColor,
      showModal,
      isShowModal,
      detailDic,
      hearingDic,
      userDic,
      getStatusColor,
      childAction,
    }
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.3rem;
  font-weight: 500;
}
.title_:hover {
  color: rgb(65, 56, 155);
}
.date-text {
  color: rgb(173, 173, 173);
}
</style>
