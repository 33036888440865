import firebase from 'firebase/app'

export default class GetSingleData {
  constructor() {
    this.label = ''
  }

  /**
   * docIDから目的のDictionaryを取得
   * @param {*} payload
   * @returns {Object}
   */
  async fetch(payload) {
    const { collection, targetIdLabel, id } = payload
    let dataDic = {}
    try {
      await firebase.firestore().collection(collection)
        .where(targetIdLabel, '==', id)
        .get()
        .then(sn => {
          if (!sn.empty) {
            sn.forEach(doc => {
              dataDic = doc.data()
            })
          }
        })

      const response = {
        status: 'success',
        data: dataDic,
      }
      return response
    } catch (error) {
      const response = {
        status: 'error',
        msg: error,
      }
      return response
    }
  }
}
