<template>
  <div>
    <h2>求職者一覧</h2>
    <b-alert
      variant="primary"
      show
      class="mt-1"
    >
      <div class="alert-body">
        <b>プロフィール</b>を作成したユーザの一覧を表示しています。
      </div>
    </b-alert>
    <b-tabs align="right">
      <b-tab>
        <template #title>
          <feather-icon icon="FlagIcon" />
          <span class="iconSmartPhone">対応中</span>
        </template>
        <Display
          v-if="status === 1"
          :data-list="profileList"
          :user-list="userList"
          @child-action="refetch"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span class="iconSmartPhone">完了</span>
        </template>
        <Display
          v-if="status === 1"
          :data-list="profileDoneList"
          :user-list="userList"
          @child-action="refetch"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BAlert, BTabs, BTab,
} from 'bootstrap-vue'
import Display from '@/components/admin/user/Display.vue'
import useListUser from '@/components/admin/user/useListUser'

export default {
  components: {
    BAlert,
    Display,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const {
      profileList,
      profileDoneList,
      adminList,
      status,
      userList,
      refetch,
    } = useListUser()

    return {
      profileList,
      profileDoneList,
      adminList,
      status,
      userList,
      refetch,
    }
  },
}
</script>
