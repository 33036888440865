<template>
  <div>
    <h3>基本情報</h3>
    <div class="">
      求職者の<b>プロフィール</b>と<b>アンケート</b>を表示しています。
    </div>
    <div class="mt-2">
      <small>ステータス</small>
    </div>
    <b-row>
      <b-col lg="6">
        <div class="">
          <v-select
            v-model="selected"
            :options="choice"
            :get-option-label="val => val.labelName"
          />
        </div>
      </b-col>
      <b-col>
        <div class="text-left">
          <b-button
            variant="primary"
            class="btn-wishlist"
            @click="updateData(userDic)"
          >
            <feather-icon
              icon="UploadCloudIcon"
              class="mr-50"
            />
            <span>更新</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-tabs align="right">
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span class="iconSmartPhone">プロフィール</span>
        </template>
        <Profile
          :data-dic="dataDic"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="Edit3Icon" />
          <span class="iconSmartPhone">アンケート</span>
        </template>
        <Hearing
          :data-dic="hearingDic"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BTabs, BTab, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { showAge } from '@/components/js/filter/birthday'
import Profile from '@/components/admin/user/Profile.vue'
import Hearing from '@/components/admin/user/Hearing.vue'
import vSelect from 'vue-select'
import { ref, onMounted } from '@vue/composition-api'
import useListUser from '@/components/admin/user/useListUser'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BButton,
    Profile,
    Hearing,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    dataDic: {
      type: Object,
      default: () => {},
    },
    hearingDic: {
      type: Object,
      default: () => {},
    },
    userDic: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    updateStatus: {
      handler() {
        if (this.updateStatus) this.makeToast('success')
      },
    },
    userDic: {
      handler() {
        if (Object.keys(this.userDic).length !== 0 && this.userDic.status) this.selected = this.choice.find(v => v.status === this.userDic.status.status)
      },
      deep: true,
    },
  },
  setup(props, { emit }) {
    const {
      update,
      updateStatus,
    } = useListUser()

    const selected = ref({})
    const choice = [
      { labelName: 'プロフ作成済み', status: 'profile' },
      { labelName: 'ヒアリング準備中', status: 'hearing' },
      { labelName: '提案準備中', status: 'proposition' },
      { labelName: '面接準備中', status: 'contact' },
      { labelName: '面接決定', status: 'interview' },
      { labelName: '完了', status: 'done' },
    ]
    onMounted(() => {
      if (Object.keys(props.userDic).length !== 0 && props.userDic.status) {
        selected.value = choice.find(v => v.status === props.userDic.status.status)
      }
    })
    const updateData = async userDic => {
      await update(userDic, selected.value)
      emit('child-action')
    }
    return {
      showAge,
      choice,
      selected,
      updateData,
      updateStatus,
    }
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast('データが更新されました🎉', {
        title: '更新完了！',
        variant,
        solid: true,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
